import { render, staticRenderFns } from "./ListAdd2.vue?vue&type=template&id=486aea96&scoped=true"
import script from "./ListAdd2.vue?vue&type=script&lang=js"
export * from "./ListAdd2.vue?vue&type=script&lang=js"
import style0 from "./ListAdd2.vue?vue&type=style&index=0&id=486aea96&prod&lang=css"
import style1 from "./ListAdd2.vue?vue&type=style&index=1&id=486aea96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486aea96",
  null
  
)

export default component.exports