<template>
  <div style="background: white">
    <!-- <HeaderBar /> -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <v-container fluid id="sub-bar">
        <v-row>
          <v-col cols="11">
            <v-btn text @click="showMenu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <div id="lbl-screen-name">リスト登録パターン2</div>
          </v-col>
          <v-col cols="1">
            <v-btn text id="btn-logout" class="menu-btn" @click="logout">
              <img src="../../assets/img/logout_menu_icon.png" />ログアウト</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-row>
              <v-col cols="1" />
              <v-col cols="4">
                <div class="search-label">
                  {{ $t("label.lbl_supplier") }}<span class="require">*</span>
                </div>
              </v-col>
              <v-col cols="7">
                <v-autocomplete
                  ref="country"
                  v-model="suppliersSelected"
                  :items="dummy_supplierList"
                  :label="$t('label.lbl_supplier')"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-col cols="1" />
              <v-col cols="5">
                <div class="search-label">
                  {{ $t("label.lbl_arrivalScheduleNo") }}<span class="require">*</span>
                </div>
              </v-col>
              <v-col xl="6">
                <v-text-field solo v-model="txt_arrivalScheduleNo" maxlength="10" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-row>
              <v-col cols="1" />
              <v-col cols="4">
                <div class="search-label">
                  {{ $t("label.lbl_arrivalScheduleDate") }}<span class="require">*</span>
                </div>
              </v-col>
              <v-col cols="3">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="txt-delivery-date"
                      v-model="arrivalScheduleDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :single-line="true"
                      class="txt-single"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    id="calendar-arrival-schedule-date"
                    v-model="arrivalScheduleDateCal"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="4">
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate">＜</v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate">＞</v-btn>
                <v-btn id="btn-today" class="day-ctrl-now-btn" @click="nowDateReset">{{
                  $t("btn.btn_today")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-row>
              <v-col cols="1" />
              <v-col cols="4">
                <div class="search-label">
                  {{ $t("label.lbl_arrival") }}<span class="require">*</span>
                </div>
              </v-col>
              <v-col cols="7">
                <v-select
                  v-model="arrivalSelected"
                  :items="dummy_arrivalList"
                  solo
                  :label="$t('label.lbl_arrival')"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row>
              <v-col cols="1" />
              <v-col cols="5">
                <div class="search-label">
                  {{ $t("label.lbl_slipNo") }}<span class="require">*</span>
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field class="txt-single" v-model="txt_slipNo" maxlength="10" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-row>
              <v-col cols="1" />
              <v-col cols="4">
                <div class="search-label">
                  {{ $t("label.lbl_isPaid") }}<span class="require">*</span>
                </div>
              </v-col>
              <v-col cols="7">
                <v-btn-toggle v-model="isPaid" color="blue darken-2" class="toggle black--text">
                  <v-btn id="btn-pack" class="btn" value="1">
                    {{ $t("btn.btn_paid") }}
                  </v-btn>
                  <v-btn id="btn-load" class="btn" value="2">
                    {{ $t("btn.btn_free") }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="time-table"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-pagination
          disable-sort
          :hide-default-footer="true"
          height="600px"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-btn color="primary" dark class="mb-2" v-on:click="add">
                  {{ $t("btn.btn_add") }}
                </v-btn>
              </template>
              <!-- 入荷予定登録詳細ポップアップ-->
              <!-- 新製品登録ポップアップ-->
            </v-toolbar>
          </template>
          <template v-slot:item.productCd="{ item }">
            <v-autocomplete
              ref="country"
              v-model="item.productCd"
              :items="productList"
              @change="(event) => changeProductNm(event)"
              :label="$t('label.lbl_productName')"
            ></v-autocomplete>
            <v-dialog v-model="dialogNewProduct">
              <v-card>
                <v-card-title>
                  <span class="text-h5">新製品登録</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="popNewProductCd" label="製品コード"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="popNewProductNm" label="製品名"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="popNewProductClose">
                    {{ $t("btn.btn_close") }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="popNewProductSave">
                    {{ $t("btn.btn_insert") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.productAcc="{ item }">
            <tr rowspan="2">
              <td><v-text-field solo v-model="item.productAcc" maxlength="10" /></td>
            </tr>
          </template>
          <template v-slot:item.productQty="{ item }">
            <tr>
              <td>
                <v-text-field solo v-model="item.productQty" maxlength="10" />
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field solo v-model="item.productQty" maxlength="10" />
              </td>
            </tr>
          </template>
          <template v-slot:item.caseSu="{ item }">
            <v-text-field solo v-model="item.caseSu" maxlength="10" />
          </template>
          <template v-slot:item.ballSu="{ item }">
            <v-text-field solo v-model="item.ballSu" maxlength="10" />
          </template>
          <template v-slot:item.baraSu="{ item }">
            <v-text-field solo v-model="item.baraSu" maxlength="10" />
          </template>
          <template v-slot:item.lot="{ item }">
            <v-text-field solo v-model="item.lot" maxlength="10" />
          </template>
          <template v-slot:item.expireDate="{ item }">
            <v-text-field solo v-model="item.expireDate" maxlength="10" />
          </template>
          <template v-slot:item.accInfos="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.accInfos }}
                </div>
              </template>
              <span>{{ item.accInfos }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.deleteData="{ item }">
            <v-btn small @click="deleteItem(item)"> {{ $t("btn.btn_delete") }} </v-btn>
          </template>
          <template v-slot:item.location="{ item }">
            <v-text-field solo v-model="item.location" maxlength="10" />
            <v-btn small @click="addLocation(item)">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-btn id="btn-pack" class="btn" value="1" @click="init">
              {{ $t("btn.btn_back") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn id="btn-pack" class="btn" value="1" @click="sendData">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
      />
      <!-- <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locProductCd="locProductCd"
        />
      </v-dialog> -->
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
//import { appConfig } from "../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
//import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
export default {
  name: "listAdd2",
  components: {
    // HeaderBar,
    Loading,
    SimpleDialog,
    sideMenu,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // ロケーションダイアログ
    dialogLocation: false,
    // 新製品登録ダイアログ
    dialogNewProduct: false,
    // 新製品コード
    popNewProductCd: "",
    // 新製品名
    popNewProductNm: "",
    // 一覧index
    editedIndex: -1,
    // 入荷予定登録ダイアログ
    dialogAddProudct: false,
    // 荷主伝票番号
    txt_slipNo: "",
    // 有償/無償
    isPaid: "1",
    // 入荷先
    arrivalSelected: "all",
    // 取引先
    suppliersSelected: "all",
    // メニュー
    openMenu: null,
    // 入荷予定No.
    txt_arrivalScheduleNo: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    // 賞味期限日付キャレンダー
    dateExpire: false,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    expireDateCal: "",

    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productCd",
        width: "200px",
        align: "left",
        sortable: false,
        class: "tableHeader",
      },
      {
        text: i18n.tc("label.lbl_productAccessory"),
        value: "productAcc",
        width: "200px",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_productQty"),
        value: "productQty",
        width: "200px",
        align: "center",
        sortable: false,
      },
      { text: "ケース（荷姿）数", value: "caseSu", width: "200px", align: "left" },
      { text: "ボール（中箱）数", value: "ballSu", width: "200px", align: "center" },
      { text: "バラ（端数）", value: "baraSu", width: "200px", align: "center" },
      { text: i18n.tc("label.lbl_lot"), value: "lot", width: "200px", align: "center" },
      { text: "賞味期限", value: "expireDate", width: "200px", align: "center" },
      { text: "付帯情報", value: "accInfos", width: "200px", align: "center" },
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "200px", align: "center" },
      { text: "格納予定ロケーション登録", value: "location", width: "200px", align: "center" },
    ],
    inputList: [],

    dummy_supplierList: [
      { text: "ALL", value: "all" },
      { text: "取引先1（supplier1）", value: "supplier1" },
      { text: "取引先2（supplier2）", value: "supplier2" },
      { text: "取引先3（supplier3）", value: "supplier3" },
      { text: "取引先4（supplier4）", value: "supplier4" },
      { text: "取引先5（supplier5）", value: "supplier5" },
    ],
    dummy_arrivalList: [
      { text: "ALL", value: "all" },
      { text: "入荷先1", value: "arrival1" },
      { text: "入荷先2", value: "arrival2" },
      { text: "入荷先3", value: "arrival3" },
      { text: "入荷先4", value: "arrival4" },
      { text: "入荷先5", value: "arrival5" },
    ],
    dummy_input: {
      productNm: "製品名",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
    },
    defaultItem: {
      productNm: "",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
    },
    productList: [
      { text: "", value: "" },
      { text: "新製品（product0）", value: "product0" },
      { text: "製品1（product1）", value: "product1" },
      { text: "製品2（product2）", value: "product2" },
      { text: "製品3（product3）", value: "product3" },
      { text: "製品4（product4）", value: "product4" },
    ],
  }),
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    makeDummy() {
      this.inputList = [];
      this.inputList.push({
        productNm: "製品名",
        productCd: "製品コード",
        productAcc: "製品付帯",
        productQty: "0",
        caseSu: "0",
        ballSu: "0",
        baraSu: "0",
        lot: "ロット番号",
        expireDateCal: this.formatDate(
          this.dateCalc(dateTimeHelper.convertJstDateTime(new Date()))
        ),
        expireDate: this.formatDate(this.dateCalc(dateTimeHelper.convertJstDateTime(new Date()))),
      });
    },
    init() {
      this.arrivalSelected = "all";
      this.suppliersSelected = "all";
      this.txt_arrivalScheduleNo = "";
      this.txt_slipNo = "";
      this.makeDummy();
      this.nowDateReset();
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷予定日付を－１日します。
     */
    prevDate() {
      let date = new Date(this.arrivalScheduleDateCal);
      this.arrivalScheduleDateCal = this.dateCalc(date.setDate(date.getDate() - 1));
    },
    /**
     * 入荷予定日付を＋１日します。
     */
    nextDate() {
      let date = new Date(this.arrivalScheduleDateCal);
      this.arrivalScheduleDateCal = this.dateCalc(date.setDate(date.getDate() + 1));
    },
    /**
     * 入荷予定日付を当日にリセットします。
     */
    nowDateReset() {
      this.arrivalScheduleDateCal = this.dateCalc(dateTimeHelper.convertJstDateTime(new Date()));
    },
    /**
     * 与えられた日付から年月日を文字列で返します。
     */
    dateCalc(date) {
      const dt = new Date(date);
      return dt.toISOString().substr(0, 10);
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    // 詳細画面閉じる処理
    close() {
      this.dialogAddProudct = false;
      this.$nextTick(() => {
        this.dummy_input = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    // 詳細→一覧
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 1; i <= 20; i++) {
        var idx = i < 10 ? "0" + i : i;
        accInfos.push(this.dummy_input["accInfo" + idx]);
      }
      this.dummy_input.accInfos = accInfos.join("\n");

      // 製品名処理
      for (var j = 0; j < this.productList.length; j++) {
        if (this.productList[j].value == this.dummy_input.productCd) {
          this.dummy_input.productNm = this.productList[j].text;
          break;
        }
      }
      // 賞味期限処理
      this.dummy_input.expireDate = this.expireDate;
    },
    // 詳細から登録
    save() {
      this.setItemToList();
      if (this.editedIndex > -1) {
        Object.assign(this.inputList[this.editedIndex], this.dummy_input);
      } else {
        this.inputList.push(this.dummy_input);
      }
      this.close();
    },
    // 修正
    editItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.dummy_input = Object.assign({}, item);
      this.expireDate = this.dummy_input.expireDate;
      this.dialogAddProudct = true;
    },
    // 削除
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);
    },
    // ロケーション登録
    addLocation(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.dummy_input = Object.assign({}, item);
      this.locProductCd = this.dummy_input.productCd;

      this.dialogLocation = true;
    },
    // 製品登録時新製品処理のイベント
    changeProductNm(value) {
      if (value == "product0") {
        this.dummy_input.productNm =
          this.dummy_input.productNm + "（" + this.popNewProductCd + "）";
        this.dummy_input.productCd = this.popNewProductCd;
        this.dialogNewProduct = true;
      }
    },
    // 新製品登録ダイアログ閉じる
    popNewProductClose() {
      this.dialogNewProduct = false;
    },
    // 新製品登録ダイアログ登録
    popNewProductSave() {
      this.productList.push({ text: this.popNewProductNm, value: this.popNewProductCd });
      this.dialogNewProduct = false;
    },
    // ロケーション登録
    saveLocation(data) {
      this.dummy_input.warehouse = data;
      Object.assign(this.inputList[this.editedIndex], this.dummy_input);
      this.dialogLocation = false;
    },
    // 一覧登録
    sendData() {},
    add() {
      this.inputList.push(this.defaultItem);
    },
  },
  computed: {
    arrivalScheduleDate() {
      return this.formatDate(this.arrivalScheduleDateCal);
    },
    expireDate() {
      return this.formatDate(this.expireDateCal);
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  #lbl-screen-name {
    padding: 0.1rem 0 0 0.5rem;
    font-size: x-large;
    font-weight: bold;
    display: inline-block;
  }
  .right {
    text-align: right;
  }
}
.v-btn.v-btn--has-bg {
  background: $color-obj-base;
  &.api-btn {
    width: 7rem;
  }
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}

button {
  &.v-btn {
    &.day-ctrl-btn {
      padding: 0;
      width: 2rem;
      min-width: 2.5rem;
    }
    &.day-ctrl-now-btn {
      padding: 0;
      width: 4rem;
      min-width: 4rem;
    }
  }
}
.list-single ::v-deep {
  div.v-input__slot {
    border-radius: 0.4rem;
  }
}
.list-single ::v-deep,
.list-multi ::v-deep {
  div.v-input__icon {
    background: #95b3d7;
    border-radius: 0.3rem;
  }
}
.list-single ::v-deep,
.txt-single ::v-deep {
  z-index: 50;
  padding-top: 0;
  padding-right: 0;
  font-size: large;
}
.tableHeader {
  background: #ff5252;
}
</style>
